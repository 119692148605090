import React from "react";
import "./Services.css"
import service01 from "./service_01.jpg"
import service02 from "./service_02.jpg"
import service03 from "./service_03.jpg"
import service04 from "./images/policies.jpg"
import service05 from "./images/moneylaun2.jpg"
import service06 from "./images/registration.jpg"


const Services = (props) => {
    const { changePage } = props
    return (
        <div className="services" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-heading">
                            <h2><em>Services</em></h2>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item">
                            <img decoding="async" loading="lazy" src={service01} alt="" />
                            <div className="down-content">
                                <h4>Expert Witness</h4>
                                <p>As an expert witness in the securities industry, Lorne is someone qualified with special knowledge, skills, training and experience to express an opinion based on information personally observed or provided by others.
                                    An expert’s duty is to assist the court and not to be an advocate for any party.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item">
                            <img decoding="async" loading="lazy" src={service02} alt="" />
                            <div className="down-content">
                                <h4>Complaint Handling</h4>
                                <p>Lorne’s approach to complaint handling is to provide a fair, person-focused, time sensitive, complaint resolution process with clearly
                                    communicated outcomes and reasons.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item">
                            <img  decoding="async"loading="lazy" src={service03} alt="" />
                            <div className="down-content">
                                <h4>Policies, Procedures</h4>
                                <p>Your policies are your firm’s rules and guidelines for how your firm and employees are to achieve your specific goals.
                                    The procedures are the instructions on how the policies are to be met.
                                    Lorne can help you maintain and implement effective policies and procedures.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item">
                            <img decoding="async" loading="lazy" src={service04} alt="" />
                            <div className="down-content">
                                <h4>Money Laundering</h4>
                                <p>Money laundering is the illegal process of making large amounts of money generated by criminal activity appear to have come from a legitimate source. The prevention of money laundering has become an international effort.
                                    Lorne can assist your firm’s effort to combat money laundering.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item">
                            <img decoding="async" loading="lazy" src={service05} alt="" />
                            <div className="down-content">
                                <h4>Compliance and Supervision</h4>
                                <p>Compliance and supervision are an integral part of a firm’s general business activities.
                                    Lorne can help your firm define and manage the specific roles and responsibilities with respect to the compliance and supervisory roles and tasks. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-item">
                            <img decoding="async" loading="lazy" src={service06} alt="" />
                            <div className="down-content">
                                <h4>Risk Assessment</h4>
                                <p>Lorne can assist to identify and evaluate risks and hazards to your enterprise and provide guidance to manage and mitigate those risks.</p>
                            </div>
                        </div>
                        <br />
                    </div>
                        <a href="#viewpoint" className="filled-button">Read More</a>
                </div>
            </div>
        </div>
    )
}

export default Services